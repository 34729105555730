body {
  --bs-body-bg: #333333;
  font-family: Arial, sans-serif;
}


.form-select:focus {
  border-color: whitesmoke;
  box-shadow: none;
}
.form-select:checked {
  border-color: whitesmoke;
}

.form-check-input:checked {
  background-color: transparent;
  border-color: whitesmoke;
  box-shadow: none;
}

.form-check-input {
  background-color: transparent;
  border-color: whitesmoke;
  box-shadow: none;
}

.form-check-input:focus {
  background-color: transparent;
  border-color: whitesmoke;
  box-shadow: none;
}

@keyframes radioShadow {
  0%   { box-shadow: 0 0 0 0.05rem rgba(250, 250, 250, 0.25); }
  50%  { box-shadow: 0 0 0 0.35rem rgba(250, 250, 250, 0.25); }
  100% { box-shadow: 0 0 0 0.05rem rgba(250, 250, 250, 0.25); }
}

.form-check-input[type=radio] {
  background-color: transparent;
  animation-name: radioShadow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.tooltip-inner {
  --bs-tooltip-color: whitesmoke;
  --bs-tooltip-bg: black;
}

.tab-content {
  flex-grow: 1;
}

.tab-pane {
  height: 100%;
}

.nav-tabs {
  --bs-nav-tabs-border-color: grey;
}

.nav-tabs .nav-link.active {
  border-color: grey;
  border-bottom: 1px solid #333333;
}

.table {
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2c3034;
  border: 1px solid grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333333;
  border: 1px solid grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #212529;
}