.tabs > li > button {
    color: white;
}

.centeredContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: whitesmoke;
    width: 100%;
    margin: 0;
}